// Imports

import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'

import dropzoneStyles from './dropzone.module.css'

// Dropzone

function Dropzone({minSize, maxSize, handleDrop, kind}) {
  
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        handleDrop({kind, file})
      }
      reader.readAsArrayBuffer(file)
    })
    
  }, [])

  const {getRootProps, getInputProps, isDragActive, rejectedFiles, acceptedFiles} = useDropzone({onDrop, maxSize: maxSize, multiple: false, handleDrop: handleDrop, kind: kind})
  const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize

  return (
    <div className={dropzoneStyles.container} {...getRootProps()}>
      <input className={dropzoneStyles.input} {...getInputProps()} />
      <h3 className={dropzoneStyles.title}>
        {isDragActive ? "Drop your file here!" : ""}
        {(!isDragActive &&  acceptedFiles.length < 1) ? "Drag and drop a file, or click to select one" : ""}
        {(!isDragActive &&  acceptedFiles.length > 0) ? "Your file was uploaded successfully" : ""}
      </h3>
      <h4 className={dropzoneStyles.tooLarge}>
        {(isFileTooLarge && acceptedFiles.length < 1) ? "That file is too large" : ""}
      </h4>
      <h4 className={dropzoneStyles.maxAllowed}>
        {(isFileTooLarge || acceptedFiles.length > 0) ? "" : "The maximum allowed file size is 5MB"}
      </h4>
      {acceptedFiles.length > 0 && acceptedFiles.map(acceptedFile => (
        <li className={dropzoneStyles.acceptedFile}>
          Uploaded File: <span>{acceptedFile.name}</span>
        </li>
      ))}
    </div>
  )
}

export default Dropzone;
