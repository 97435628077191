// Imports

import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import animateScrollTo from 'animated-scroll-to';

import { beginLoading, endLoading } from "../../actions/ui";
import formStyles from "./form-new-customer.module.css";
import { createMessage } from '../../actions/messages';
import Dropzone from "../dropzone/dropzone";

// Contact Form

class NewCustomerForm extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.reset = this.reset.bind(this)
  }

  static propTypes = {
    createMessage: PropTypes.func.isRequired
  }

  initialState() {
    return {
      _replyto: "",
      business_name: "",
      phone: "",
      street: "",
      apt: "",
      city: "",
      state: "",
      zip: "",
      cultivator_mme_registrant: "",
      cultivator_mme_id: "",
      cultivator_mme_file: null,
      production_mme_registrant: "",
      production_mme_id: "",
      production_mme_file: null,
      invoice_name: "",
      invoice_email: "",
      invoice_phone: "",
      cc_account_name: "",
      cc_primary_contact: "",
      message: "",
    }
  }

  reset() {
    animateScrollTo(0);
    this.setState(this.initialState());
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleDrop(props) {
    const {kind, file} = props;
    let obj = {}
    obj[kind] = file
    this.setState(obj)
    console.log(this.state)
  }

  submitForm(ev) {
    ev.preventDefault();
    const options = {
      title: 'Submitting',
      subtitle: 'Please be patient while we submit your information.'
    }
    this.props.beginLoading(options);
    this.props.beginLoading(options);
    const form = ev.target;
    const data = new FormData(form);
    data.append("production_mme_file", this.state.production_mme_file);
    data.append("cultivator_mme_file", this.state.cultivator_mme_file);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      this.props.createMessage({ SUCCESS: "Your information was received. We'll be in touch!"})
      if (xhr.readyState !== 4) return;
      if (xhr.status === 200) {
        this.props.createMessage({ customerReceived: "Your information was received. We'll be in touch!"})
        this.reset();
      }
      this.props.endLoading();
    };
    xhr.send(data);
  }

  render() {
    return (
      <form onSubmit={this.submitForm} action="https://formspree.io/xzbbyepz" method="POST" encType="multipart/form-data" className={formStyles.contactForm}>
        <div className={formStyles.form__rowFirst}>
          <h3 className={formStyles.title}>Primary Contact</h3>
        </div>
        <div className={formStyles.form__row}>
          <div className={formStyles.form__input}>
            <label className={formStyles.form__label}>Email</label>
            <input className={formStyles.form__field}
              value={this.state._replyto}
              name="_replyto"
              type="email"
              placeholder=""
              required
              onChange={this.handleChange}/>
          </div>
          <div className={formStyles.form__input}>
            <label className={formStyles.form__label}>Business Name</label>
            <input className={formStyles.form__field}
              value={this.state.business_name}
              name="business_name"
              type="text"
              placeholder="" 
              required
              onChange={this.handleChange}/>
          </div>
          <div className={formStyles.form__input}>
            <label className={formStyles.form__label}>Phone</label>
            <input className={formStyles.form__field}
              value={this.state.phone}
              name="phone"
              type="text"
              placeholder="" 
              required
              onChange={this.handleChange}/>
          </div>
        </div>
        <div className={formStyles.form__row}>
          <h3 className={formStyles.title}>Address</h3>
        </div>
        <div className={formStyles.form__row}>
          <div className={formStyles.form__inputDouble}>
            <label className={formStyles.form__label}>Street</label>
            <input className={formStyles.form__field}
              value={this.state.street}
              name="street"
              type="text"
              placeholder=""
              required
              onChange={this.handleChange}/>
          </div>
          <div className={formStyles.form__inputDouble}>
            <label className={formStyles.form__label}>Apt, Suite, Etc.</label>
            <input className={formStyles.form__field}
              value={this.state.apt}
              name="apt"
              type="text"
              placeholder=""
              required
              onChange={this.handleChange}/>
          </div>
          <div className={formStyles.form__input}>
            <label className={formStyles.form__label}>City</label>
            <input className={formStyles.form__field}
              value={this.state.city}
              name="city"
              type="text"
              placeholder=""
              required
              onChange={this.handleChange}/>
          </div>
          <div className={formStyles.form__input}>
            <label className={formStyles.form__label}>State</label>
            <input className={formStyles.form__field}
              value={this.state.state}
              name="state"
              type="text"
              placeholder=""
              required
              onChange={this.handleChange}/>
          </div>
          <div className={formStyles.form__input}>
            <label className={formStyles.form__label}>Zip</label>
            <input className={formStyles.form__field}
              value={this.state.zip}
              name="zip"
              type="text"
              placeholder=""
              required
              onChange={this.handleChange}/>
          </div>
        </div>
        <div className={formStyles.form__row}>
          <h3 className={formStyles.title}>Cultivator Licensing</h3>
        </div>
        <div className={formStyles.form__row}>
          <div className={formStyles.form__input}>
            <label className={formStyles.form__label}>Cultivator MME Registrant</label>
            <input className={formStyles.form__field}
              value={this.state.cultivator_mme_registrant}
              name="cultivator_mme_registrant"
              type="text"
              placeholder=""
              required
              onChange={this.handleChange}/>
          </div>
          <div className={formStyles.form__input}>
            <label className={formStyles.form__label}>Cultivator MME ID</label>
            <input className={formStyles.form__field}
              value={this.state.cultivator_mme_id}
              name="cultivator_mme_id"
              type="text"
              placeholder=""
              required
              onChange={this.handleChange}/>
          </div>
          <div className={formStyles.form__inputFull}>
            <label className={formStyles.form__label}>Cultivator Recreational License</label>
            <Dropzone minSize={0} maxSize={5242880} handleDrop={this.handleDrop} kind="cultivator_mme_file"/>
          </div>
        </div>
        <div className={formStyles.form__row}>
          <h3 className={formStyles.title}>Production Licensing</h3>
        </div>
        <div className={formStyles.form__row}>
          <div className={formStyles.form__input}>
            <label className={formStyles.form__label}>Production MME Registrant</label>
            <input className={formStyles.form__field}
              value={this.state.production_mme_registrant}
              name="production_mme_registrant"
              type="text"
              placeholder=""
              required
              onChange={this.handleChange}/>
          </div>
          <div className={formStyles.form__input}>
            <label className={formStyles.form__label}>Prouction MME ID</label>
            <input className={formStyles.form__field}
              value={this.state.production_mme_id}
              name="production_mme_id"
              type="text"
              placeholder=""
              required
              onChange={this.handleChange}/>
          </div>
          <div className={formStyles.form__inputFull}>
            <label className={formStyles.form__label}>Production Recreational License</label>
            <Dropzone minSize={0} maxSize={5242880} handleDrop={this.handleDrop} kind="production_mme_file"/>
          </div>
        </div>
        <div className={formStyles.form__row}>
          <h3 className={formStyles.title}>Invoice Information</h3>
        </div>
        <div className={formStyles.form__row}>
          <div className={formStyles.form__input}>
            <label className={formStyles.form__label}>Name</label>
            <input className={formStyles.form__field}
              value={this.state.invoice_name}
              name="invoice_name"
              type="text"
              placeholder=""
              required
              onChange={this.handleChange}/>
          </div>
          <div className={formStyles.form__input}>
            <label className={formStyles.form__label}>Email</label>
            <input className={formStyles.form__field}
              value={this.state.invoice_email}
              name="invoice_email"
              type="text"
              placeholder=""
              required
              onChange={this.handleChange}/>
          </div>
          <div className={formStyles.form__input}>
            <label className={formStyles.form__label}>Phone</label>
            <input className={formStyles.form__field}
              value={this.state.invoice_phone}
              name="invoice_phone"
              type="text"
              placeholder=""
              required
              onChange={this.handleChange}/>
          </div>
        </div>
        <div className={formStyles.form__row}>
          <h3 className={formStyles.title}>Confident Cannabis</h3>
        </div>
        <div className={formStyles.form__row}>
          <div className={formStyles.form__input}>
            <label className={formStyles.form__label}>Account Name</label>
            <input className={formStyles.form__field}
              value={this.state.cc_account_name}
              name="cc_account_name"
              type="text"
              placeholder=""
              required
              onChange={this.handleChange}/>
          </div>
          <div className={formStyles.form__input}>
            <label className={formStyles.form__label}>Primary Contact</label>
            <input className={formStyles.form__field}
              value={this.state.cc_primary_contact}
              name="cc_primary_contact"
              component="input"
              type="text"
              placeholder=""
              required
              onChange={this.handleChange}/>
          </div>
        </div>
        <div className={formStyles.form__row}>
          <div className={formStyles.form__inputStandalone}>
            <label className={formStyles.form__label}>Message</label>
            <textarea className={formStyles.form__textarea}
              value={this.state.message}
              name="message"
              type="text"
              placeholder="Please include any additional details you'd like..."
              onChange={this.handleChange}/>
          </div>
        </div>
        <div className={formStyles.form__buttons}>
          <button className={formStyles.form__buttonSubmit} type="submit">
            Submit
          </button>
          <button className={formStyles.form__buttonReset} type="button" onClick={this.reset}>
            Clear All Fields
          </button>
        </div>
      </form>
  )}
}

export default connect(
  null, 
  { createMessage, beginLoading, endLoading }
)(NewCustomerForm);