// Imports

import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import NewCustomerForm from "../components/form-new-customer/form-new-customer"
import getStartedPageStyles from "../styles/pages/get-started.module.css"

// Contact

const GetStartedPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="ACE | Nevada's Leading Cannabis Testing Lab" />
      <div className={getStartedPageStyles.hero}>
        <div className={getStartedPageStyles.callToAction}>
          <h3 className={getStartedPageStyles.title}>Customer Setup</h3>
          <p className={getStartedPageStyles.details}>Please include a photo copy of each license.</p>
        </div>
      </div>
      <div className={getStartedPageStyles.backgroundContainer}></div>
      <div className={getStartedPageStyles.outerContainer}>
        <div className={getStartedPageStyles.innerContainer}>
          <div className={getStartedPageStyles.form__container}>
            <NewCustomerForm />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default GetStartedPage

export const query = graphql`
  query {
    allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "contact-page"}}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              email
                phone
                fax
                addressLine1
                addressLine2
                city
                state
                zip
          }
        }
      }
    }
  }
}`
